import { Container } from "react-bootstrap";

export const Footer = () => {
    return (
        <section id="footer" className="footer">
            <Container>
                <p>
                    © 2024 Seven Ducks Studios
                </p>
            </Container>
        </section>
    );
}