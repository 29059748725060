export const Divider = () => {
    return (
        <div className="divider" />
    );
}

export const SpacerHalf = () => {
    return (
        <div className="spacer-half" />
    );
}

export const Spacer = () => {
    return (
        <div className="spacer" />
    );
}

export const SpacerBig = () => {
    return (
        <div className="spacer-big" />
    );
}