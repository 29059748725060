import { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import { mdiDice3 } from "@mdi/js";
import Icon from '@mdi/react';

import sitemap from "./WikiSitemap";

export const Search = () => {
    const [reload, setReload] = useState(0);

    function visitRandomPage(e) {
        e.preventDefault();
        var item = sitemap[Math.floor(Math.random() * sitemap.length)];
        window.location.href = "/#/wiki/" + item.link;
    }

    function handleOnSelect(item) {
        window.location.href = "/#/wiki/" + item.link;
        setReload(reload + 1);
    }

    return (
        <div style={{ display: "flex" }}>
            <div className="social-icon">
                <a
                    href="/#"
                    onClick={e => visitRandomPage(e)}
                    title="Random Page">
                    <Icon path={mdiDice3} size={1} color="white" />
                </a>
            </div>
            <ReactSearchAutocomplete
                key={reload}
                items={sitemap}
                maxResults={6}
                showClear={false}
                autoFocus={false}
                onSelect={handleOnSelect}
                placeholder="Search the Elysian Eclipse Wiki"
                showNoResultsText="No Results"
                styling={{
                    height: "30px !important",
                    fontFamily: "Segoe UI",
                    fontSize: "0.95em",
                    searchIconMargin: "5px",
                    clearIconMargin: "5px",
                    iconColor: "#fff",
                    color: "#fff",
                    backgroundColor: "#121212",
                    hoverBackgroundColor: "#242424",
                    lineColor: "#F5A623",
                    border: "none",
                    borderRadius: "0"
                }} />
        </div>
    );
}
