import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import reactStringReplace from "react-string-replace";

import * as Icons from '@mdi/js';
import Icon from '@mdi/react';

import { Spacer, SpacerBig, SpacerHalf } from "./Dividers";
import { NotFound } from "./NotFound";
import { CategoryBox } from "./WikiCategory";
import sitemap from "./WikiSitemap";
import showcase2 from "../assets/img/ShowcaseCellEditor.png";
import showcase1 from "../assets/img/ShowcaseStarSystem.png";
import showcase3 from "../assets/img/ShowcaseValley.png";

export const Wiki = () => {
    const { title } = useParams();
    const [comp, setComp] = useState(<section id="wiki" className="wiki section" />);

    useEffect(() => {
        const loadData = async () => {
            import("../wikipages/" + title + ".js")
                .then(response => {
                    document.title = title.replaceAll("_", " ") + " | Elysian Eclipse";
                    setComp(build(response.default, title));
                })
                .catch(error => {
                    document.title = "404 | Elysian Eclipse";
                    setComp(<NotFound />);
                });
        }
        loadData();
    }, [title]);

    return (comp);
}

function build(data, title) {
    return (
        <section id="wiki" className="wiki section neb-blue">
            <Container>
                <SpacerBig />
                {(() => {
                    if (data.fullWidth) {
                        return (
                            <>
                                <h1>{title.replaceAll("_", " ")}</h1>
                                <SpacerHalf />
                                {getSections(data.sections)}
                                {<CategoryBox categories={data.categories} />}
                                <Spacer />
                            </>
                        );
                    }
                    else {
                        return (
                            <Row>
                                <Col md={12} lg={12}>
                                    <h1>{title.replaceAll("_", " ")}</h1>
                                </Col>
                                <Col md={12} lg={3} className="order-lg-last">
                                    {getInfoBox(data.infobox)}
                                    {getTableOfContents(data.sections)}
                                    {getInfoImages(data.images)}
                                    <Spacer />
                                </Col>
                                <Col md={12} lg={9}>
                                    <SpacerHalf />
                                    {getSections(data.sections)}
                                    {<CategoryBox categories={data.categories} />}
                                    <Spacer />
                                </Col>
                            </Row>
                        );
                    }
                })()}
            </Container>
        </section>
    );
}

function getInfoBox(data) {
    if (!data) {
        return;
    }
    return (
        <>
            <SpacerHalf />
            <table className="info-box">
                <thead>
                    <tr>
                        <th colSpan={2}>{data.title}</th>
                    </tr>
                    {(() => {
                        if (data.image) {
                            return (
                                <tr>
                                    <td colSpan={2}>
                                        <img src={data.image} alt="" />
                                    </td>
                                </tr>
                            );
                        }
                        else if (data.icon) {
                            return (
                                <tr>
                                    <td colSpan={2}>
                                        <Icon
                                            path={Icons[data.icon]}
                                            size={8}
                                            color={data.color} />
                                    </td>
                                </tr>
                            );
                        }
                    })()}
                </thead>
                <tbody>
                    {data.rows.map((row) => {
                        return (
                            <tr key={row.key}>
                                <th>{row.key}</th>
                                <td>{addLinks(row.value)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

function getTableOfContents(data) {
    if (!data) {
        return;
    }
    let titleIndex = 1;
    return (
        <>
            <SpacerHalf />
            <div className="table-of-contents">
                <p>Contents</p>
                <ul>
                    <li>
                        <button
                            onClick={() => scrollToTop()}>
                            Top
                        </button>
                    </li>
                    {data.map((section, index) => {
                        switch (section.type) {
                            case "title":
                                let titleId = "#title" + titleIndex++;
                                return (
                                    <li key={index}>
                                        <button
                                            onClick={() => scrollIntoView(titleId)}>
                                            {section.text}
                                        </button>
                                    </li>
                                );
                            case "subtitle":
                                let subtitleId = "#title" + titleIndex++;
                                return (
                                    <li key={index} className="no-list-bullets">
                                        <ul>
                                            <li>
                                                <button
                                                    onClick={() => scrollIntoView(subtitleId)}>
                                                    {section.text}
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                );
                            default:
                                return null;
                        }
                    })}
                </ul>
            </div>
        </>
    );
}

function getInfoImages(data) {
    if (!data) {
        return;
    }
    return data.map((image) => {
        return (
            <>
                <SpacerHalf />
                <div key={image.image} className="info-image">
                    <a href={image.image} target="_blank" rel="noreferrer">
                        <figure>
                            <img src={image.image} alt="" />
                        </figure>
                    </a>
                    <p>{addLinks(image.caption)}</p>
                </div>
            </>
        );
    });
}

function getSections(data) {
    if (!data) {
        return;
    }
    let titleIndex = 1;
    return data.map((section, index) => {
        return (
            <div key={index}>
                {(() => {
                    switch (section.type) {
                        case "title":
                            return getTitle(section, titleIndex++);
                        case "subtitle":
                            return getSubtitle(section, titleIndex++);
                        case "paragraph":
                            return getParagraph(section);
                        case "pageCount":
                            return getPageCount();
                        case "list":
                            return getList(section);
                        case "iconList":
                            return getIconList(section);
                        case "boxes":
                            return getBoxes(section);
                        case "table":
                            return getTable(section);
                        case "images":
                            return getImages(section);
                        case "gallery":
                            return getGallery();
                        case "allPages":
                            return getAllPages();
                        default:
                            return null;
                    }
                })()}
            </div>
        );
    });
}

function getTitle(section, titleIndex) {
    return (
        <>
            <SpacerHalf />
            <h3 id={"title" + titleIndex}>
                {section.text}
            </h3>
        </>
    );
}

function getSubtitle(section, titleIndex) {
    return (
        <>
            <SpacerHalf />
            <h5 id={"title" + titleIndex}>
                {section.text}
            </h5>
        </>
    );
}

function getParagraph(section) {
    return (
        <p>{addLinks(section.text)}</p>
    );
}

function getPageCount() {
    return (
        <p>
            The Wiki currently contains <a href={"/#/wiki/All_Pages"}>
                {Object.keys(sitemap).length + " pages"}
            </a>, curated by the developer.
        </p>
    );
}

function getList(section) {
    return (
        <ul>
            {section.list.map((listItem, index) => {
                return (
                    <li key={index}>
                        {addLinks(listItem)}
                    </li>
                );
            })}
        </ul>
    );
}

function getIconList(section) {
    return (
        <ul className="icon-list">
            {section.list.map((listItem, index) => {
                return (
                    <li key={index}>
                        <Icon
                            path={Icons[section.icons[index]]}
                            size={1}
                            color={section.colors[index]} />
                        {addLinks(listItem)}
                    </li>
                );
            })}
        </ul>
    );
}

function getBoxes(section) {
    return (
        <Row>
            {section.boxes.map((box, index) => {
                return (
                    <Col sm={12} md={4} key={index}>
                        <div className="custom-box">
                            <div className="custom-box-header">
                                {box.header}
                            </div>
                            <div className="custom-box-body">
                                {getSections(box.sections)}
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
}

function getTable(section) {
    return (
        <div className="table-responsive">
            <table>
                <tbody>{getTableRows(section.rows)}</tbody>
            </table>
            <p />
        </div>
    );

}

function getTableRows(rows) {
    return rows.map((row, rowIndex) => {
        return (
            <tr key={rowIndex}>
                {
                    row.map((cell, cellIndex) => {
                        if (rowIndex === 0) {
                            return <th key={cellIndex}>{cell}</th>;
                        }
                        else if (cell.startsWith("https")) {
                            return <td key={cellIndex} className="image-cell"><img src={cell} alt="" /></td>;
                        }
                        else {
                            return <td key={cellIndex}>{addLinks(cell)}</td>;
                        }
                    })
                }
            </tr>
        );
    });
}

function getImages(section) {
    let style = section.uniformHeight ? { height: section.uniformHeight, objectFit: "cover" } : {};

    return (
        <Row>
            {section.images.map((image) => {
                return (
                    <Col sm={12} md={image.wide ? 8 : 4}>
                        <SpacerHalf />
                        <div key={image.image} className="info-image">
                            <a href={image.image} target="_blank" rel="noreferrer">
                                <figure>
                                    <img src={image.image} alt="" style={style} />
                                </figure>
                            </a>
                            <p>{addLinks(image.caption)}</p>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
}

function getGallery() {
    return (
        <>
            <SpacerHalf />
            <Row>
                <Col sm={12} md={4}>
                    <img src={showcase1} alt="Showcase 1" className="gallery-image"></img>
                    <SpacerHalf />
                </Col>
                <Col sm={12} md={4}>
                    <img src={showcase2} alt="Showcase 2" className="gallery-image"></img>
                    <SpacerHalf />
                </Col>
                <Col sm={12} md={4}>
                    <img src={showcase3} alt="Showcase 3" className="gallery-image"></img>
                    <SpacerHalf />
                </Col>
            </Row>
            <SpacerHalf />
        </>
    );
}

function getAllPages() {
    return (
        <ul className="column-list">
            {sitemap.map((page, index) => {
                return (
                    <li>
                        <a key={index} href={"/#/wiki/" + page.link}>{page.name}</a>
                    </li>
                );
            })}
        </ul>
    )
}

function scrollToTop() {
    window.scrollTo(0, 0);
}

function scrollIntoView(selector) {
    let element = document.querySelector(selector);
    element?.scrollIntoView();
};

function addLinks(text) {
    return reactStringReplace(text, /\[\[(.*?)\]\]/g, (match, index) => {
        let prefix = match.includes("https://") ? "" : "/#/wiki/";
        if (match.includes("|")) {
            let matches = match.split("|");
            return <a key={index} href={prefix + matches[1]}>{matches[0]}</a>;
        }
        else {
            return <a key={index} href={prefix + match}>{match}</a>;
        }
    });
}