import { useEffect, useRef } from "react";

export const Kofi = () => {
    const widgetRef = useRef(null);

    useEffect(() => {
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = 'https://storage.ko-fi.com/cdn/widget/Widget_2.js';
            script.async = true;
            script.onload = () => {
                if (window.kofiwidget2) {
                    window.kofiwidget2.init('Support the Devs', 'rgba(0, 0, 0, 0.75)', 'wauzmons/tiers');
                    const widgetHTML = window.kofiwidget2.getHTML();
                    if (widgetRef.current) {
                        widgetRef.current.innerHTML = widgetHTML;
                    }
                }
            };
            document.body.appendChild(script);
        };

        loadScript();
    }, []);

    return <div ref={widgetRef} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}></div>;
}