import { Col, Container, Row } from "react-bootstrap";

import { Spacer, SpacerBig } from "./Dividers";
import crab from "../assets/img/CrabFish.png";

export const FAQ = () => {
    return (
        <section id="faq" className="faq section neb-blue">
            <Container>
                <SpacerBig />
                <h1>Frequently Asked Questions</h1>
                <Row>
                    <Col sm={12} md={6}>
                        <Spacer />
                        <h2>Development and Release</h2>
                        <b>Q: When will the game release?</b>
                        <p>
                            A: Version 1.0.0 will release when it's done.
                            Rushing a release would only hurt the quality of the game.
                            However there are regular demos.
                        </p>
                        <b>Q: How do I download the demo?</b>
                        <p>
                            A: Check out the
                            <a href="/#/earlyaccess"> Early Access Page </a>
                            for infos about the demos.
                        </p>
                        <b>Q: How much will the game cost?</b>
                        <p>
                            A: We would love to make the game completely free,
                            but it would require a really big amount of patrons
                            to cover the cost of continuous development.
                        </p>
                        <b>Q: How many people are working on the game?</b>
                        <p>
                            A: One full time developer and lots of volunteers.
                        </p>
                        <b>Q:  Can I help working on the game?</b>
                        <p>
                            A: We don't have the capacity for another developer,
                            but you can find public channels to work on concept art and music
                            on our official<a href="https://discord.gg/dhHJp5a"> Discord</a> server.
                        </p>
                        <b>Q: Will the game release on consoles and/or mobile?</b>
                        <p>
                            A: Only a PC release for Windows and Linux is planned.
                        </p>
                        <b>Q: Why is the game called Elysian Eclipse?</b>
                        <p>
                            A: It's an anagram of "spicy alien eels". Don't question it.
                        </p>
                        <b>Q: Are we in danger of being sued by EA?</b>
                        <p>
                            A: No. Game mechanics themselve are not subject to copyright.
                            We aren't breaking any of Electronic Arts' patents and
                            own the usage rights for all assets in EE.
                        </p>
                        <Spacer />
                        <h2>Game Features</h2>
                        <b>Q: What are the seven stages of evolution?</b>
                        <p>
                            A: Cell, Aquatic, Creature, Tribal, Medieval, Industrial and Space.
                        </p>
                        <b>Q: How will the game differ from Spore?</b>
                        <p>
                            A: Elysian Eclipse will feature complex multi-biome planets,
                            levelable skills, crafting and research systems,
                            fleet management and cities with player-made layouts.
                        </p>
                        <b>Q: Will the game support mods?</b>
                        <p>
                            A: Yes. An official modding API is planned.
                        </p>
                        <b>Q: Will you be able to play with other players?</b>
                        <p>
                            A: No. There was a multiplayer prototype, but it got removed.
                        </p>
                        <b>Q: Will there be a flora / plant editor?</b>
                        <p>
                            A: Yes, but it will only be useable from the main menu.
                        </p>
                        <b>Q: Will you be able to become a kaiju / megafauna / epic creature?</b>
                        <p>
                            A: While you can't play as one yourself,
                            the planets and even space will contain a variety of
                            massive monsters and gentle giants.
                        </p>
                        <b>Q: Will you be able to stay in the water and become an aquatic civilization?</b>
                        <p>
                            A: No, this is just too much work to include into the game.
                        </p>
                        <b>Q: Will there be sexual dimorphism?</b>
                        <p>
                            A: No, but creature variations may be added as a future update.
                        </p>
                        <b>Q: Will there be an equivalent to galactic adventures?</b>
                        <p>
                            A: No, but you will be able to land on planets
                            and explore them in space stage.
                        </p>
                        <b>Q: Will the game have DLCs?</b>
                        <p>
                            A: We want to release regular content updates.
                            Microtransactions like paid editor parts won't be in the game.
                            Modern monetization sucks!
                        </p>
                    </Col>
                    <Col sm={12} md={6}>
                        <img src={crab} alt="Crabfish" />
                    </Col>
                </Row>
                <Spacer />
            </Container>
        </section>
    );
}