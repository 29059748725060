import { Col, Container, Row } from "react-bootstrap";

import { Spacer } from "./Dividers";
import showcase1 from "../assets/img/ShowcaseStarSystem.png";
import showcase2 from "../assets/img/ShowcaseCellEditor.png";
import showcase3 from "../assets/img/ShowcaseValley.png";

export const Features = () => {
    return (
        <section id="features" className="features">
            <Container>
                <Spacer />
                <Row>
                    <Col sm={12} md={7}>
                        <img src={showcase1} alt="Showcase 1"></img>
                    </Col>
                    <Col sm={12} md={5} className="align-self-center">
                        <h1>Explore</h1>
                        <p>
                            Elysian Eclipse takes place in an infinite, procedural universe,
                            where each playthrough is set in an unique galaxy
                            with hundreds of different stars and planets.
                        </p>
                    </Col>
                </Row>
                <Spacer />
                <Row>
                    <Col sm={12} md={7} className="order-md-last">
                        <img src={showcase2} alt="Showcase 2"></img>
                    </Col>
                    <Col sm={12} md={5} className="align-self-center">
                        <h1>Evolve</h1>
                        <p>
                            Design your own species and guide it through seven stages of evolution.
                            Unlock dozens of parts to assemble creatures, buildings and vehicles
                            in an extensive editor.
                        </p>
                    </Col>
                </Row>
                <Spacer />
                <Row>
                    <Col sm={12} md={7}>
                        <img src={showcase3} alt="Showcase 3"></img>
                    </Col>
                    <Col sm={12} md={5} className="align-self-center">
                        <h1>Endure</h1>
                        <p>
                            Let your species learn skills and research technologies
                            to survive many different biomes, filled with dangerous predators
                            and hazardous environments.
                        </p>
                    </Col>
                </Row>
                <Spacer />
            </Container>
        </section>
    );
}