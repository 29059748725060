import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { CupHotFill, Discord, Mastodon, Steam, Youtube } from 'react-bootstrap-icons';

import logo from "../assets/img/LogoElysianEclipse.png";
import { Search } from "./WikiSearch";

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState("");

    useEffect(() => {
        const onScroll = () => {
            var target = "";
            var bottom = window.scrollY + window.innerHeight + 100 > document.body.scrollHeight;
            document.querySelectorAll(".section").forEach(section => {
                if (bottom || window.scrollY >= section.offsetTop - 200) {
                    target = section.id;
                }
            });
            setActiveLink(target);
        }

        window.addEventListener("scroll", onScroll);
        onScroll();

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <>
            <Navbar className="d-none d-lg-flex">
                {getNavbarContents()}
            </Navbar>
            <Navbar className="d-lg-none d-flex nav-mobile">
                {getNavbarContents()}
            </Navbar>
            <div id="wikinav">
                <Container>
                    <Search />
                </Container>
            </div>
        </>
    );

    function getNavbarContents() {
        return (
            <Container>
                <Navbar.Brand href="/#/" className="d-none d-lg-flex">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Nav>
                    <Nav.Link
                        href="/#/"
                        className={activeLink === "home" ? "selected navbar-link" : "navbar-link"}>
                        Home
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.tumblr.com/wauzmons/tagged/elysian%20eclipse"
                        className="navbar-link"
                        target="_blank"
                        rel="noreferrer">
                        Blog
                    </Nav.Link>
                    <Nav.Link
                        href="https://trello.com/b/3GntuYDl"
                        className="navbar-link"
                        target="_blank"
                        rel="noreferrer">
                        Roadmap
                    </Nav.Link>
                    <Nav.Link
                        href="/#/earlyaccess"
                        className={activeLink === "earlyaccess" ? "selected navbar-link" : "navbar-link"}>
                        Early Access
                    </Nav.Link>
                    <Nav.Link
                        href="/#/faq"
                        className={activeLink === "faq" ? "selected navbar-link" : "navbar-link"}>
                        FAQ
                    </Nav.Link>
                    <Nav.Link
                        href="/#/wiki/Elysian_Eclipse_Wiki"
                        className={activeLink === "wiki" ? "selected navbar-link" : "navbar-link"}>
                        Wiki
                    </Nav.Link>
                </Nav>
                <span className="navbar-text">
                    <div className="social-icon">
                        <a
                            href="https://store.steampowered.com/app/1774190/Elysian_Eclipse"
                            title="Steam"
                            target="_blank"
                            rel="noreferrer">
                            <Steam size={24} style={{ width: "75%" }} color="white" />
                        </a>
                        <a
                            href="https://ko-fi.com/wauzmons/tiers"
                            title="Donations"
                            target="_blank"
                            rel="noreferrer">
                            <CupHotFill size={24} style={{ width: "75%" }} color="white" />
                        </a>
                        <a
                            href="https://discord.com/invite/dhHJp5a"
                            title="Discord"
                            target="_blank"
                            rel="noreferrer">
                            <Discord size={24} style={{ width: "75%" }} color="white" />
                        </a>
                        <a
                            href="https://mastodon.online/@wauzmons"
                            title="Mastodon"
                            target="_blank"
                            rel="noreferrer">
                            <Mastodon size={24} style={{ width: "75%" }} color="white" />
                        </a>
                        <a
                            href="https://www.youtube.com/Wauzmons"
                            title="YouTube"
                            target="_blank"
                            rel="noreferrer">
                            <Youtube size={24} style={{ width: "75%" }} color="white" />
                        </a>
                    </div>
                </span>
            </Container>
        );
    }
}