import { Col, Container, Row } from "react-bootstrap";

import { mdiArrowRightBoldCircle } from '@mdi/js';
import Icon from '@mdi/react';

import blorb3 from "../assets/img/BlorbSprite_3.png";
import blorb4 from "../assets/img/BlorbSprite_4.png";

export const NotFound = () => {
    return (
        <section id="home" className="banner">
            <Container>
                <Row className="align-items-center">
                    <Col sm={12} md={8} className="banner-text">
                        <h1>404 Not Found</h1>
                        <p style={{ maxWidth: 600 }}>
                            The page you are looking for doesn't exist
                            or has been moved.
                        </p>
                        <a
                            href="/#/">
                            <button className="action-button">
                                Back to Home<Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                            </button>
                        </a>
                    </Col>
                    <Col sm={12} md={4}>
                        <a
                            href="/#/">
                            <img
                                src={blorb3}
                                onMouseEnter={e => e.currentTarget.src = blorb4}
                                onMouseLeave={e => e.currentTarget.src = blorb3}
                                alt="Blorb" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}