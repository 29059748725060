import { useEffect, useState } from "react";

import { SpacerHalf } from "./Dividers";

export const CategoryBox = (data) => {
    if (!data.categories) {
        return;
    }
    return (
        <>
            <SpacerHalf />
            <table className="category-box">
                <thead>
                    <tr>
                        <th colSpan={2}>Categories</th>
                    </tr>
                </thead>
                <tbody>
                    {data.categories.map((category) => {
                        return <CategoryRow key={category} category={category} />
                    })}
                </tbody>
            </table>
        </>
    );
}

const CategoryRow = (data) => {
    const [comp, setComp] = useState(<></>);

    useEffect(() => {
        const loadData = async () => {
            import("../wikicategories/" + data.category + ".js")
                .then(response => setComp(BuildRow(response.default, data.category)))
                .catch(_error => setComp(<></>));
        }
        loadData();
    }, [data.category]);

    return (comp);
}

function BuildRow(data, title) {
    if (!data) {
        return;
    }
    return (
        <tr>
            <th>{title.replaceAll("_", " ")}</th>
            <td>
                <ul>
                    {data.pages.map((page) => {
                        return (
                            <li key={page}>
                                <a href={"/#/wiki/" + page}>{page.replaceAll("_", " ")}</a>
                            </li>
                        );
                    })}
                </ul>
            </td>
        </tr>
    );
}