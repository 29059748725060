import { Col, Container, Row } from "react-bootstrap";

import { mdiArrowRightBoldCircle } from '@mdi/js';
import Icon from '@mdi/react';

import blorb1 from "../assets/img/BlorbSprite_1.png";
import blorb2 from "../assets/img/BlorbSprite_2.png";

export const Banner = () => {
    return (
        <section id="home" className="banner section">
            <Container>
                <Row className="align-items-center">
                    <Col sm={12} md={8} className="banner-text">
                        <h1>Elysian Eclipse</h1>
                        <p style={{ maxWidth: 600 }}>
                            Elysian Eclipse is a Sandbox Evolution Simulator,
                            letting you evolve from an amoeba into a space traveling sentient being.
                            Create your own creatures, buildings and vehicles to conquer procedural planets.
                            <br /><br />
                            Turn the galaxy into your personal playground!
                        </p>
                        <a
                            href="https://store.steampowered.com/app/1774190/Elysian_Eclipse"
                            target="_blank"
                            rel="noreferrer">
                            <button className="action-button">
                                Wishlist on Steam<Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                            </button>
                        </a>
                    </Col>
                    <Col sm={12} md={4}>
                        <a
                            href="/#/earlyaccess">
                            <img
                                src={blorb1}
                                onMouseEnter={e => e.currentTarget.src = blorb2}
                                onMouseLeave={e => e.currentTarget.src = blorb1}
                                alt="Blorb" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}