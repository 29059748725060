import { Col, Container, Row } from "react-bootstrap";

import { mdiArrowRightBoldCircle, mdiFormatListText, mdiHelpCircle, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';

import { Spacer, SpacerHalf } from "./Dividers";

export const Portal = () => {
    return (
        <Container>
            <Row>
                <Col sm={12} md={4}>
                    <a
                        href="https://www.tumblr.com/wauzmons/tagged/elysian%20eclipse"
                        target="_blank"
                        rel="noreferrer">
                        <button className="action-button">
                            <Icon path={mdiFormatListText} size={1.5} color="white" style={{ marginRight: 10 }} />
                            Read the Blog<Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                        </button>
                    </a>
                    <SpacerHalf />
                    <p>
                        Very regular updates and announcements are posted on Wauzmons' Tumblr Blog,
                        allowing you to stay up-to-date with the game's development. (May contain ducks.)
                    </p>
                </Col>
                <Col sm={12} md={4}>
                    <a href="/#/faq">
                        <button className="action-button">
                            <Icon path={mdiHelpCircle} size={1.5} color="white" style={{ marginRight: 10 }} />
                            Read the FAQ<Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                        </button>
                    </a>
                    <SpacerHalf />
                    <p>
                        We get asked tons of questions about our game every day.
                        By reading the Frequently Asked Questions before asking,
                        you can save us and yourself a lot of time.
                    </p>
                </Col>
                <Col sm={12} md={4}>
                    <a href="/#/wiki/Elysian_Eclipse_Wiki">
                        <button className="action-button">
                            <Icon path={mdiWeb} size={1.5} color="white" style={{ marginRight: 10 }} />
                            Read the Wiki<Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                        </button>
                    </a>
                    <SpacerHalf />
                    <p>
                        If you want to learn more about Elysian Eclipse and its development process,
                        the developer-curated wiki is a great source of information.
                    </p>
                </Col>
            </Row>
            <Spacer />
        </Container>
    );
}