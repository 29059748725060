import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Spacer, SpacerHalf } from "./Dividers"

const YOUTUBE_PLAYLIST_ITEMS_API = "https://www.googleapis.com/youtube/v3/playlistItems";

const KEY = "AIzaSyAmOLrguOszxagVnbKCOXvOUC9SV9_a8NQ";

const MAX_RESULTS = 4;

const PLAYLIST_ID = "PLvEqADmskUlOgz1VweUAjL1F_Ndga-InS";

export const Videos = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function GetData() {
            if (isLocalhost()) {
                return;
            }
            const response = await fetch(
                YOUTUBE_PLAYLIST_ITEMS_API
                + "?key=" + KEY
                + "&part=snippet&maxResults=" + MAX_RESULTS
                + "&playlistId=" + PLAYLIST_ID);
            const data = await response.json();
            if (response.ok) {
                setData(data);
            }
        }
        GetData();
    }, []);

    if (data === null) {
        if (isLocalhost()) {
            return (
                <Container>
                    <Spacer />
                    <Row>
                        {[...Array(4)].map((e, i) =>
                            <Col key={i} sm={6} md={3} className="feed-tile">
                                <a
                                    href="/#"
                                    className="feed-link">
                                    <div className="feed">
                                        <p><b>by Uploader</b></p><br />
                                        <p>Video Title</p>
                                    </div>
                                </a>
                            </Col>
                        )}
                    </Row>
                    <SpacerHalf />
                </Container>
            );
        }
        else {
            return (
                <Container />
            );
        }
    }

    return (
        <Container>
            <Spacer />
            <Row>
                {data.items.map((item) => {
                    return (
                        <Col key={item.snippet.resourceId.videoId} sm={6} md={3} className="feed-tile">
                            <a
                                href={"https://youtu.be/" + item.snippet.resourceId.videoId}
                                target="_blank"
                                rel="noreferrer"
                                className="feed-link">
                                <div className="feed">
                                    <img
                                        src={item.snippet.thumbnails.maxres.url}
                                        alt="Thumbnail"
                                        className="feed-thumbnail"></img>
                                    <p><b>by {item.snippet.videoOwnerChannelTitle}</b></p><br />
                                    <p>{item.snippet.title}</p>
                                </div>
                            </a>
                        </Col>
                    );
                })}
            </Row>
            <SpacerHalf />
        </Container>
    );

    function isLocalhost() {
        return window.location.hostname === "localhost";
    }
}