import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { HashRouter, Outlet, Route, Routes } from "react-router-dom";

import { Banner } from "./components/Banner";
import { Divider } from "./components/Dividers";
import { EarlyAccess } from "./components/EarlyAccess";
import { FAQ } from "./components/FAQ";
import { Features } from "./components/Features";
import { Footer } from "./components/Footer";
import { Kofi } from "./components/Kofi";
import { NavBar } from "./components/NavBar";
import { NotFound } from "./components/NotFound";
import { Portal } from "./components/Portal";
import { ScrollToTop } from "./components/ScrollToTop";
import { Videos } from "./components/Videos";
import { Wiki } from "./components/Wiki";

function App() {
  return (
    <>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<PageHome />} />
            <Route path="earlyaccess" element={<PageEarlyAccess />} />
            <Route path="faq" element={<PageFAQ />} />
            <Route path="wiki/:title" element={<PageWiki />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </HashRouter>
      <Kofi />
    </>
  );
}

function Layout() {
  return (
    <div className="App">
      <Outlet />
      <Divider />
      <Footer />
    </div>
  );
}

function PageHome() {
  document.title = "Elysian Eclipse";

  return (
    <>
      <NavBar />
      <Banner />
      <Divider />
      <Portal />
      <Features />
      <Videos />
    </>
  );
}

function PageEarlyAccess() {
  document.title = "Early Access | Elysian Eclipse";

  return (
    <>
      <NavBar />
      <EarlyAccess />
    </>
  );
}

function PageWiki() {
  // Title Handled in the Wiki Component

  return (
    <>
      <NavBar />
      <Wiki />
    </>
  );
}

function PageFAQ() {
  document.title = "FAQ | Elysian Eclipse";

  return (
    <>
      <NavBar />
      <FAQ />
    </>
  );
}

function PageNotFound() {
  document.title = "404 | Elysian Eclipse";

  return (
    <>
      <NavBar />
      <NotFound />
    </>
  );
}

export default App;
